import * as React from 'react';
import useFetch from 'fetch-suspense';
import styled from 'styled-components';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';

interface ImageFormats {
  hidpi: string;
  normal: string;
  one_x: string;
  two_x: string;
  teaser: string;
}

interface ShotType {
  id: number;
  animated: boolean;
  description?: string;
  images: ImageFormats;
  low_profile: boolean;
  tags: string[];
  title: string;
}

// This fetching component will be delayed by Suspense until the fetch request
//   resolves. The return value of useFetch will be the response of the server.
const FetchDribbble = () => {
  // Fetch from Greenohouse
  const shots = useFetch(
    `https://api.dribbble.com/v2/user/shots?access_token=3dd73839cdc8ca3016168ef11004835e3696ed46681d32a330dac2c107976cca&page=1&per_page=3`
  ) as ShotType[];

  return shots.length ? (
    <Wrapper>
      <Row middle="xs" center="xs">
        {shots.map(shot => (
          <Col xs={8} md={6} lg={4} key={`shot-${shot.id}`}>
            <Shot src={shot.images.hidpi} />
          </Col>
        ))}
      </Row>
      <Link
        href="https://dribbble.com/rguillaume"
        target="_blank"
        rel="noreferrer"
      >
        Check more on Dribbble
      </Link>
    </Wrapper>
  ) : (
    <p>No shots found</p>
  );
};

const Dribbble = () => (
  <React.Suspense fallback="Loading...">
    <FetchDribbble />
  </React.Suspense>
);

const Wrapper = styled(Grid)`
  padding: 80px 0;
  text-align: center;
`;

const Shot = styled.img`
  width: 100%;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid ${({ theme }) => theme.colors.bg};
  margin-bottom: 20px;
`;

const Link = styled.a`
  display: block;
  margin: 20px auto 0;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.dribbble};
  }
`;

export default Dribbble;
