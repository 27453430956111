import * as React from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

import SEO from '@components/seo';
import Layout from '@components/layout';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Skills from '@components/skills';
import Dribbble from '@components/dribbble';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "apps.png" }) {
        childImageSharp {
          fixed(width: 426, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Home" />
      <IntroBlock>
        <Row middle="xs" center="xs">
          <Col xs={11} lg={6}>
            <IntroPic>
              <Img fixed={data.file.childImageSharp.fixed} />
            </IntroPic>
          </Col>
          <Col xs={11} lg={6}>
            <h3>Brainstorm & Research</h3>
            <p>
              Every <b>project</b> starts by defining <b>objectives</b>,{' '}
              <b>problems</b> & <b>boundaries</b>. I always try to find a way to{' '}
              <b>make the most</b> of every projects.
            </p>
            <h3>Wire Framing</h3>
            <p>
              Developing the basic <b>structure</b> of a website or application
              and establishing <b>user flows</b>. Starting from <b>sketches</b>{' '}
              and after several iterations defining a final <b>storyboard</b>.
            </p>
            <h3>Design</h3>
            <p>
              Creating beautiful <b>UI</b> and functional{' '}
              <b>user experiences</b>. Staying <b>innovative</b> but{' '}
              <b>minimalist</b> at the same time is my priority.
            </p>
            <h3>Front End Development</h3>
            <p>
              As a <b>developer</b> you always need to <b>stay tuned</b> to
              technologies & language evolutions. Developing{' '}
              <b>pixel perfect</b> designs and coding{' '}
              <b>responsives websites</b>. Using <b>HTML5</b>, <b>CSS3</b> and{' '}
              <b>Javascript</b> is my daily occupation. I also use many other
              tools such as <b>Git, Grunt, Bower, ...</b>
            </p>
          </Col>
        </Row>
      </IntroBlock>
      <Skills />
      {typeof window !== 'undefined' && <Dribbble />}
    </Layout>
  );
};

export default IndexPage;

const IntroBlock = styled(Grid)`
  padding: 80px 0;
`;

const IntroPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 500px;
  border-radius: 250px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.bg};
`;
