import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Skills = () => {
  const data = useStaticQuery(graphql`
    fragment squareImage on File {
      childImageSharp {
        fixed(width: 70, height: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query {
      react: file(relativePath: { eq: "react.png" }) {
        ...squareImage
      }
      redux: file(relativePath: { eq: "redux.png" }) {
        ...squareImage
      }
      JS: file(relativePath: { eq: "JS.png" }) {
        ...squareImage
      }
      TS: file(relativePath: { eq: "TS.png" }) {
        ...squareImage
      }
      SC: file(relativePath: { eq: "SC.png" }) {
        ...squareImage
      }
      sketch: file(relativePath: { eq: "sketch.png" }) {
        ...squareImage
      }
    }
  `);
  return (
    <Wrapper>
      <p>
        Here are few of the <strong>languages</strong> and{' '}
        <strong>tools</strong> I work with everyday.
      </p>
      <List variants={container} initial="hidden" animate="visible">
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.react.childImageSharp.fixed} />
          <SkillText>React</SkillText>
        </Item>
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.redux.childImageSharp.fixed} />
          <SkillText>Redux</SkillText>
        </Item>
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.JS.childImageSharp.fixed} />
          <SkillText>Javascript</SkillText>
        </Item>
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.TS.childImageSharp.fixed} />
          <SkillText>Typescript</SkillText>
        </Item>
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.SC.childImageSharp.fixed} />
          <SkillText>Styled Components</SkillText>
        </Item>
        <Item variants={item} whileHover={{ scale: 1.2 }}>
          <StyledImg fixed={data.sketch.childImageSharp.fixed} />
          <SkillText>Sketch</SkillText>
        </Item>
      </List>
    </Wrapper>
  );
};
const StyledImg = styled(Img)`
  vertical-align: top;
`;

const SkillText = styled.p`
  opacity: 0;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-size: 10px;
  white-space: nowrap;
`;

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.bg};
  padding: 60px 0 40px;
  text-align: center;
`;

const List = styled(motion.ul)`
  padding: 40px 0 0;
`;

const Item = styled(motion.li)`
  padding: 10px;
  margin: 0 40px 60px;
  border-radius: 45px;
  display: inline-block;
  vertical-align: middle;
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  :hover {
    ${SkillText} {
      opacity: 1;
    }
  }
`;

export default Skills;
